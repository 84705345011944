import Vue from "vue"
import Vuex from "vuex"
import api from "@/services/api"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    page: {},

    site_id: "txp",
    settings: null,
    main_pages: null,

    homeID: "jzru01nGg",
    aboutID: "M1BEjYS-P",
    teamID: "Izjc2oiGh",
    expertiseID: "t8JM-q-pa",
    programmesID: "LihitYSA3",
    casestudiesID: "zAyRMGHWh",
    contactID: "QWzLvbt4Z",

    aboutPage: null,
    teamPage: null,
    expertisePage: null,
    programmesPage: null,
    casestudiesPage: null,
    contactPage: null
  },
  getters: {
    isService(state) {
      return state.page.ItemType && state.page.ItemType.indexOf("page.programmes") == 0
    },
    isCaseStudy(state) {
      return state.page.ItemType && state.page.ItemType.indexOf("page.casestudies") == 0
    },
    mainMenu(state) {
      if (!state.main_pages) return []
      return state.main_pages.filter(p => p.ItemID != state.homeID)
    }
  },
  mutations: {
    setPage(state, page) {
      state.page = page
    },
    setSpecificPage(state, payload) {
      state[payload.prop] = payload.page
    },
    setSettings(state, settings) {
      state.settings = settings
    },
    setMainPages(state, main_pages) {
      state.main_pages = main_pages
    }
  },
  actions: {
    getSettings({ commit, state }) {
      return new Promise(resolve => {
        if (!state.settings)
          api.getPageByItemID("SETTINGS").then(settings => {
            commit("setSettings", settings)
            resolve()
          })
        else resolve()
      })
    },
    getMainPages({ commit, state }) {
      return new Promise(resolve => {
        if (!state.main_pages)
          api.getPages("", "page.main").then(main_pages => {
            commit("setMainPages", main_pages)
            resolve()

            var promises = []

            main_pages.forEach(page => {
              api.getPageByItemID(page.ItemID).then(menu_page => {
                if (menu_page.ItemID == state.aboutID) commit("setSpecificPage", { prop: "aboutPage", page: menu_page })
                if (menu_page.ItemID == state.teamID) commit("setSpecificPage", { prop: "teamPage", page: menu_page })
                if (menu_page.ItemID == state.expertiseID)
                  commit("setSpecificPage", { prop: "expertisePage", page: menu_page })
                if (menu_page.ItemID == state.programmesID)
                  commit("setSpecificPage", { prop: "programmesPage", page: menu_page })
                if (menu_page.ItemID == state.casestudiesID)
                  commit("setSpecificPage", { prop: "casestudiesPage", page: menu_page })
                if (menu_page.ItemID == state.contactID)
                  commit("setSpecificPage", { prop: "contactPage", page: menu_page })

                promises.push(
                  api
                    .getPages("image", "page.programmes=@" + page.ItemID)
                    .then(subs => Vue.set(menu_page, "programmes", subs))
                )
                promises.push(
                  api
                    .getPages("image,company", "page.casestudies=@" + page.ItemID)
                    .then(subs => Vue.set(menu_page, "casestudies", subs))
                )
              })
            })

            Promise.all(promises).then(() => {
              commit("setMainPages", main_pages)
            })
          })
        else resolve()
      })
    }
  }
})
