import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: "/",
    //   name: "index",
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/diagnostic",
    //   name: "diagnostic",
    //   meta: { isService: true, serviceID: 0 },
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/coaching",
    //   name: "coaching",
    //   meta: { isService: true, serviceID: 1 },
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/people-development",
    //   name: "people-development",
    //   meta: { isService: true, serviceID: 2 },
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/process-improvement",
    //   name: "process-improvement",
    //   meta: { isService: true, serviceID: 3 },
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/improvement-projects",
    //   name: "improvement-projects",
    //   meta: { isService: true, serviceID: 4 },
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/awesome-merchandise-ltd",
    //   name: "awesome-merchandise-ltd",
    //   meta: { isCaseStudy: true, caseStudyID: 0 },
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/chesterfield-special-cylinders-ltd",
    //   name: "chesterfield-special-cylinders-ltd",
    //   meta: { isCaseStudy: true, caseStudyID: 1 },
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/flexiform",
    //   name: "flexiform",
    //   meta: { isCaseStudy: true, caseStudyID: 2 },
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/produmax",
    //   name: "produmax",
    //   meta: { isCaseStudy: true, caseStudyID: 3 },
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/reliance-precision-limited",
    //   name: "reliance-precision-limited",
    //   meta: { isCaseStudy: true, caseStudyID: 4 },
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    // {
    //   path: "/unison-ltd",
    //   name: "unison-ltd",
    //   meta: { isCaseStudy: true, caseStudyID: 5 },
    //   component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    // },
    {
      path: "/",
      name: "root",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    },
    {
      path: "/404-not-found",
      name: "404",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    },
    {
      path: "/:url",
      name: "page-by-url",
      component: () => import(/* webpackChunkName: "page" */ "./views/Page.vue")
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
